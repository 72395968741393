import { Link, graphql } from "gatsby";
import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import formatTimeToRead from "../utils/formatTimeToRead";

export default ({
  data: {
    markdownRemark: { frontmatter, html, timeToRead },
  },
}) => (
  <Layout showProgress>
    <SEO
      description={frontmatter.excerpt}
      lang={frontmatter.lang}
      title={frontmatter.title}
      image={frontmatter.ogImage && frontmatter.ogImage.childImageSharp.fixed.src}
    />
    <article className="pb5">
      <header className="tc ph3 pb3 ph4-ns">
        <h1 className="f2 mt0 lh-title">{frontmatter.title}</h1>
        <p className="f6 f5-l db gray mb2 mb4-ns tc">
          <time>{frontmatter.date}</time>
          <span className="mh1"> • </span>
          <span>{formatTimeToRead(timeToRead, frontmatter.lang)}</span>
        </p>
      </header>
      <div className="ph3 ph4-m ph5-l" dangerouslySetInnerHTML={{ __html: html }} />
      {frontmatter.prev || frontmatter.next ? (
        <div className="mt5 ph3 ph4-m ph5-l">
          {frontmatter.prev ? (
            <Link className="dib mb2" to={frontmatter.prev.split("@@@")[0]}>
              ← {frontmatter.prev.split("@@@")[1]}
            </Link>
          ) : null}
          {frontmatter.next ? (
            <Link className="fr dib" to={frontmatter.next.split("@@@")[0]}>
              {frontmatter.next.split("@@@")[1]} →
            </Link>
          ) : null}
        </div>
      ) : null}
    </article>
  </Layout>
);

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      frontmatter {
        date(formatString: "DD/MM/YYYY")
        title
        lang
        excerpt
        ogImage {
          childImageSharp {
            fixed(width: 600) {
              src
            }
          }
        }
        prev
        next
      }
    }
  }
`;
